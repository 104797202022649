var g = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function c(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
function p(t) {
  if (t.__esModule)
    return t;
  var r = t.default;
  if (typeof r == "function") {
    var n = function e() {
      return this instanceof e ? Reflect.construct(r, arguments, this.constructor) : r.apply(this, arguments);
    };
    n.prototype = r.prototype;
  } else
    n = {};
  return Object.defineProperty(n, "__esModule", { value: !0 }), Object.keys(t).forEach(function(e) {
    var a = Object.getOwnPropertyDescriptor(t, e);
    Object.defineProperty(n, e, a.get ? a : {
      enumerable: !0,
      get: function() {
        return t[e];
      }
    });
  }), n;
}
var s = {}, u = {};
(function(t) {
  Object.defineProperty(t, "__esModule", {
    value: !0
  }), Object.defineProperty(t, "default", {
    enumerable: !0,
    get: function() {
      return n;
    }
  });
  function r(e, a) {
    return {
      handler: e,
      config: a
    };
  }
  r.withOptions = function(e, a = () => ({})) {
    const o = function(l) {
      return {
        __options: l,
        handler: e(l),
        config: a(l)
      };
    };
    return o.__isOptionsFunction = !0, o.__pluginFunction = e, o.__configFunction = a, o;
  };
  const n = r;
})(u);
(function(t) {
  Object.defineProperty(t, "__esModule", {
    value: !0
  }), Object.defineProperty(t, "default", {
    enumerable: !0,
    get: function() {
      return e;
    }
  });
  const r = /* @__PURE__ */ n(u);
  function n(a) {
    return a && a.__esModule ? a : {
      default: a
    };
  }
  const e = r.default;
})(s);
let i = s;
var d = (i.__esModule ? i : { default: i }).default;
const f = /* @__PURE__ */ c(d), b = f(
  ({ addUtilities: t, matchUtilities: r, addBase: n, theme: e }) => {
    t({
      // disable scrollbar even when overflow is ocurring
      ".no-scrollbar": {
        "-ms-overflow-style": "none",
        "scrollbar-width": "none"
      },
      ".no-scrollbar::-webkit-scrollbar": {
        display: "none"
      },
      // select element background -- white with chevron down icon
      ".select-background": {
        backgroundColor: e("colors.white"),
        backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSIjQURCN0MwIiBzdHJva2Utd2lkdGg9IjIiPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTE5IDlsLTcgNy03LTciIC8+Cjwvc3ZnPg==)"
      },
      // rounded video palyer
      ".rounded-video": {
        video: {
          "border-radius": "1rem"
        }
      },
      ".pb-safe": {
        paddingBottom: "max(env(safe-area-inset-bottom), 6rem)"
      }
    }), r(
      {
        "translate-z": (a) => ({
          "--tw-translate-z": a,
          transform: " translate3d(var(--tw-translate-x), var(--tw-translate-y), var(--tw-translate-z)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        })
        // this is actual CSS
      },
      { values: e("translate"), supportsNegativeValues: !0 }
    ), n({
      body: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        color: e("colors.cello.500"),
        fontWeight: e("fontWeight.normal"),
        fontSize: e("fontSize.base"),
        fontFamily: e("fontFamily.body")
      },
      p: {
        fontSize: e("fontSize.base"),
        fontFamily: e("fontFamily.body")
      },
      "input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder, select:disabled, select:disabled::placeholder": {
        opacity: "1",
        "-webkit-text-fill-color": e("colors.gray.600")
      },
      "input[type='checkbox']:disabled": {
        "-webkit-appearance": "none",
        "-webkit-text-fill-color": e("colors.gray.600")
      },
      "input[type='range']": {
        cursor: "pointer",
        height: e("height.1"),
        appearance: "none",
        "-webkit-appearance": "none",
        "&:hover": {
          height: e("height.2")
        }
      }
    });
  },
  {
    theme: {
      extend: {
        // ---- colors --------------------------------
        colors: {
          cararra: {
            50: "#FDFDFD",
            100: "#FCFBFA",
            200: "#F8F7F5",
            300: "#F5F4F1",
            400: "#F1F0EC",
            500: "#EEECE7",
            600: "#BEBDB9",
            700: "#8F8E8B",
            800: "#5F5E5C",
            900: "#302F2E"
          },
          lynch: {
            50: "#F0F2F4",
            100: "#E0E5EA",
            200: "#C2CBD5",
            300: "#A3B2BF",
            400: "#8598AA",
            500: "#667E95",
            600: "#526577",
            700: "#3D4C59",
            800: "#29323C",
            900: "#14191E"
          },
          geebung: {
            50: "#F9F3EA",
            100: "#F4E6D5",
            200: "#E9CEAC",
            300: "#DDB582",
            400: "#D29D59",
            500: "#C7842F",
            600: "#9F6A26",
            700: "#774F1C",
            800: "#503513",
            900: "#281A09"
          },
          cello: {
            50: "#EBEDEF",
            100: "#D6DBDF",
            200: "#ADB7C0",
            300: "#8594A0",
            400: "#5C7081",
            500: "#334C61",
            600: "#293D4E",
            700: "#1F2E3A",
            800: "#141E27",
            900: "#0A0F13"
          },
          red: "#CA2B2A",
          green: "#265D56",
          white: "#ffffff",
          gray: {
            50: "#F9FAFB",
            100: "#F3F4F6",
            200: "#E5E7EB",
            300: "#D1D5DB",
            400: "#9CA3AF",
            500: "#6B7280",
            600: "#4B5563",
            700: "#374151",
            800: "#1F2937",
            900: "#111827"
          },
          black: "#000000"
        },
        // ---- fonts -------------------------------
        fontFamily: {
          display: [
            '"Mazius Display"',
            '"Trebuchet-MS"',
            "Arial",
            "sans-serif"
          ],
          heading: [
            '"Open Sans"',
            '"Segoe UI"',
            "Tahoma",
            "Arial",
            "sans-serif"
          ],
          body: ['"Open Sans"', '"Segoe UI"', "Tahoma", "Arial", "sans-serif"],
          accent: ['"Optician Sans"', "Tahoma", "Arial", "sans-serif"]
        },
        // ---- fontSize -------------------------------
        fontSize: {
          "5xl": ["3rem", { lineHeight: "6.25rem" }],
          "6xl": ["3.75rem", { lineHeight: "6.25rem" }],
          "7xl": ["4.5rem", { lineHeight: "6.25rem" }]
        },
        // ---- spacing -------------------------------
        spacing: {
          4.5: "1.125rem"
        },
        // ---- shadow --------------------------------
        boxShadow: {
          glow: "0px 0px 3px 0px"
        },
        // ---- strokeWidth ---------------------------
        strokeWidth: {
          1.5: "1.5px"
        },
        animation: {
          marquee: "marquee 20s linear infinite"
        },
        keyframes: {
          marquee: {
            "0%": { transform: "translateX(0%)" },
            "100%": { transform: "translateX(-100%)" }
          }
        }
      }
    }
  }
);
export {
  p as a,
  g as c,
  c as g,
  b as p
};
